import {
	mapState,
	mapGetters,
	mapMutations,
	mapActions
} from 'vuex'
const baseMixin = {
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([
			'hasLogin',
			'userInfo',
			'langValue',
			'langList'
		])
	},
	methods: {
		back(){
			this.$router.back()
		},
		formatToThreeDigits(value) {
	// 将数字转换成字符串
			if (value == undefined || value == "") {
				value = 0;
			}
			//现将数字保留两位小数,再将其三分化
			var nStr = (value * 1).toFixed(2);
			nStr += '';
			let x = nStr.split('.');
			let x1 = x[0];
			let x2 = x.length > 1 ? ',' + x[1] : '';
			var rgx = /(\d+)(\d{3})/;
			while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + '.' + '$2');
			}
			return x1 + x2
},
		trimTime(val) {
			const check = time => time < 10 ? `0${time}` : time
			return `${val.getFullYear()}-${check(val.getMonth() + 1)}-${check(val.getDate())} ${check(val.getHours())}:${check(val.getMinutes())}:${check(val.getSeconds())}`
		},
		openUrl(url){
			this.$router.push(`/${url}`)
		},
		copyUrl(url) {
			var domUrl = document.createElement("input");
			domUrl.value = url;
			domUrl.id = "creatDom";
			document.body.appendChild(domUrl);
			domUrl.select(); // 选择对象
			document.execCommand("Copy"); // 执行浏览器复制命令
			let creatDom = document.getElementById("creatDom");
			creatDom.parentNode.removeChild(creatDom);
			this.$message.success(this.langList.common.copysuccess + '!')
		},
		check(time) {
			if (time < 10) {
				time = '0' + time
			} else {
				time = time
			}
			return time;
		},
		getTime(time) {
			var timestamp = Date.now(time);
			var newYorkTime = this.timestampToTimezone(time, 'Asia/Yangon');

			return newYorkTime
		},
		getVnTime(time){
			var timestamp = Date.now(time);
			var newYorkTime = this.timestampToTimezone(time, 'Asia/Ho_Chi_Minh');

			return newYorkTime

		},
		timestampToTimezone(timestamp, timezone) {
			// 创建一个新的Date对象，使用timestamp作为参数
			var date = new Date(timestamp*1000);
			// 使用Intl.DateTimeFormat来格式化日期
			// 设置timeZone为传入的时区，并且设置year, month, day, hour, minute, second为数字格式
			var datetimeformat = new Intl.DateTimeFormat('zh-CN', {
				timeZone: timezone,
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
				hour12: false
			});

			// 使用format方法将Date对象转换为指定时区的字符串
			var formatted = datetimeformat.format(date);

			// 返回格式化后的时间字符串
			return formatted;
		},
		setTime(time){
			var timestamp = Date.now(time);
			var newYorkTime = this.timestampToTimezone(time, 'Asia/Yangon');

			return newYorkTime
		},
	}
}

export default baseMixin;
