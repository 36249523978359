import {
	createStore
} from 'vuex'
import router from '../router/index.js';
import { getUserinfo } from "../api/index.js"
export default createStore({
	state: {
		hasLogin:false,
		showSign:false,
		userInfo:{},
		langList:{},
		langValue: localStorage.getItem('lang')
	},
	mutations: {
		login(state,data){
			state.hasLogin = true
			state.userInfo = data
			localStorage.setItem("userInfo",JSON.stringify(state.userInfo));
			console.log(state);
		},
		exit(state,data){
			state.hasLogin = false
			state.userInfo = {}
			localStorage.removeItem('userInfo');
			localStorage.removeItem('token');
			console.log(state);
		},
		setLang(state,data){
			state.langList = data
			console.log(state,'state');
		},
		setSign(state,data){
			console.log('sign',data)
			let sign='';
			if(data ==0){
				sign = false
			}else{
				true
			}
			state.showSign=sign
		}
	},
	getters: {
		userInfo: state => state.userInfo,
		hasLogin: state => state.hasLogin,
		langValue: state => state.langValue,
		langList: state => state.langList
	},
	actions: {
		async getUserinfo({commit},payload){
			const res = await getUserinfo()
			if(res.code == 0){
				commit('login',res.myinfo)
				commit('setSign',res.myinfo.is_sign)
			}
		}
	},
	modules: {}
})
