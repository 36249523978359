import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import baseMixin from './utils/commonmethods.js'
import VueParticles from 'vue-particles'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import installElementPlus from './plugins/element'
// import Clipboard from 'v-clipboard'
// import VueClipboard from 'vue3-clipboard'

// import VueClipboard from 'vue-clipboard2'
const app = createApp(App);
// installElementPlus(app)

app.config.globalProperties.$onLaunched = new Promise(resolve => {
    app.config.globalProperties.$isResolve = resolve;
})
// app.use(VueClipboard)
app.use(ElementPlus)
app.use(store);
app.use(router);
app.use(VueParticles);
app.mixin(baseMixin);
app.mount('#app')

